@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: #000000e7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

nav a {
  margin: 0 1rem;
  text-decoration: none;
}
nav {
  display: flex;
  justify-content: flex-end;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #079c74;
  visibility: collapse;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  width: fit-content;
  align-items: center;
}
.btn-discord {
  width: 65px;
  padding: auto 8px;
  background: #1ebaa3;
  border-radius: 25px 15px;
  text-decoration: none;
  padding: 5px;
  margin-left: 0%;
  color: white;
  transition: 0.5s ease-in-out;
  text-align: center;
}
.btn-discord span {
  color: #fff;
}
.btn-nav {
  visibility: collapse;
}
.btn-discord:hover {
  background: #1ebaa3;
  transition: 0.5s ease-in-out;
  color: #bcc0ff;
}

@media only screen and (max-width: 1024px) {
  header {
    justify-content: space-between;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    padding-bottom: 10px;
    top: 0;
    left: 0;
    z-index: 1021;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1.5rem;
    background-color: #00000090;
    backdrop-filter: blur(20px);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
    justify-content: end;
    align-items: flex-end;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  nav .nav-close-btn {
    text-align: end;
    padding-right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  nav .btn-discord {
    background: #079c74;
    border-radius: 10px;
    color: white;
    padding: 5px;
    margin-left: 0;
  }
  .btn-out {
    visibility: collapse;
  }
  .btn-nav {
    visibility: visible;
  }
}

/* #offcanvasBody{
    height: 100;
    overflow-y:visible ;
}
.offcanvas-header{
    background: rgb(0, 0, 0);
}
@media(max-width:998px){
    #offcanvasBody{
    background: rgba(0, 0, 0, 0.303);
    backdrop-filter: blur( 20px );
}
} */
/* css for the navbar  */
/* .navbarContainer{
    height: 75px;
    background: rgba(0, 0, 0, 0.974);
}
nav{
    background-color: rgba(0, 0, 0, 0.307);
}
.navbar{
    background: rgba( 0, 0, 0, 0.7 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
}
@media  (max-width: 500px){
    .navbarContainer{
    width:40px
}
.logo-text{
    padding-bottom: 10px;
    font-size: 25px;
}


}
.navbar-brand h2{
    color: #0BC5AF;
    font-weight: 700;
} */

/*css for the logo on the navbar*/
.Logo-navbar {
  width: 48px;
  height: 42px;
  padding-left: 10px;
  align-items: center;
  margin-left: 15px;
}
/* responsiveness for logo of sellular */
.nav-logo {
  height: 50px;
  display: flex;
  align-items: center;
}
.nav-link {
  text-decoration: none;
  display: inline;
}
.list-1 {
  text-align: end;
  margin-right: 10px;
}
.nav-item {
  display: inline-block;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;
}
.logo-text {
  color: #1ebaa3;
  padding-left: 10px;
  text-decoration: 0;
  font-weight: 700;
  margin-bottom: 0;
}

.logo-box {
  text-align: center;
}
@media (max-width: 993px) {
  .button-join {
    margin-left: 0px;
  }
}

.navbar-toggler {
  border: 0px;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}
.toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  transition: all 0.2s;
}
.navbar-toggler.collapsed {
  border: 0;
  padding-right: 20px;
}

.middle-bar {
  margin: 5px auto;
}
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 10%;
}
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
.navbar-toggler.collapsed .toggler-icon {
  background-color: #13caa2;
}

.collapse {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 26%;
}
.button-join {
  background-color: #187058;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition-duration: 0.4s;
  /* justify-content:baseline; */
}
@media (max-width: 998px) {
  .button-join {
    width: 25vw;
  }
}
.fa-brands {
  padding-right: 10px;
  position: relative;
}
.list-2 {
  justify-content: end;
  text-align: end;
}
/* Nav-Link Hover effect */
.nav-link {
  padding-top: 2%;
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-size: 19px;
  box-sizing: border-box;
}
.nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0fc5b0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}
.nav-link:hover {
  color: #fff;
}
.nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.button-join:hover {
  cursor: pointer;
  background-color: #54579e;
  color: white;
  box-shadow: 0 5px 15px rgba(84, 87, 158, 0.5);
}
.button-join::before {
  height: 150%;
  width: 7px;
  content: "";
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  left: -25%;
  bottom: -25%;
  transform: rotate(40deg);
}
.button-join:hover::before {
  bottom: -25%;
  animation: slide 0.45s ease 1;
}
@keyframes AnimationName {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(-12deg, -12deg);
  }
  50% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(6deg, 6deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(-4deg, -4deg);
  }
}
.img-dis {
  position: relative;
  top: -1.3px;
}
/* CSS For Footer */
/* 
.logo {
  height: 43px;
  width: 43px;
  box-shadow: #0fc5b0;
}

footer {
  padding-top: 40px;
  background-color: #0f0f0f;
  overflow: hidden;
}
.right-footer {
  width: 50%;
}
.left-footer {
  width: 50%;
}
.Footer-Main-Container {
  display: flex;
}
.fa-linkedin-in,
.fa-envelope,
.fa-instagram {
  color: white;
  padding-top: 20px;
  transition: 1s;
  font-size: x-large;
  margin-left: 10px;
}
.right-footer-container {
  display: flex;
  width: 100%;
}
@media (max-width: 380px) {
  .left-footer {
    width: 100%;
  }
  .fa-linkedin-in,
  .fa-instagram {
    font-size: medium;
    margin-left: 0px;
    margin-right: 0px;
  }
  .fa-envelope {
    margin-left: 0px;
    margin-right: 10px;
    font-size: medium;
  }
  .right-footer {
    width: 100%;
  }
  .Footer-Main-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .example.text {
    width: 70%;
  }
}

.footer-text {
  color: #4a5568;
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  box-sizing: border-box;
}


form.example input[type="text"] {
  padding-left: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: center;
  font-size: 15px;
  border: 1px solid grey;
  float: left;
  width: 53%;
  background: #f1f1f1;
  height: 40px;
}


form.example button {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 10%;
  justify-content: center;
  background: #7bddd1;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
  height: 40px;

  margin: 0px -40px;
}

form.example button:hover {
  background: #7bddd1;
}


form.example::after {
  content: " ";
  clear: both;
  display: table;
}
@media (max-width: 500px) {
  form.example button {
    position: absolute;
    display: flex;
    height: 25px;
    left: 180px;
    width: 35px;
    float: left;
  }
  form.example input[type="text"] {
    position: absolute;
    width: 70%;
    height: 25px;
    font-size: 11px;
    justify-content: flex-end;
  }
}
.fs {
  padding-top: 2%;
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 19px;
}
@media (max-width: 400px) {
  .fs {
    font-size: 13px;
  }
  .fa-paper-plane {
    margin-top: 4px;
    font-size: 15px;
  }
}
.fs:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0fc5b0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.fs:hover {
  color: #fff;
}
.fs:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.e-mail {
  width: 75%;
  justify-content: center;
  color: white;
  font-size: 17px;
  border-left: none;
  cursor: pointer;
  height: 20px;

  margin: 0px -40px;
}
.contact-logo {
  width: 20px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
}
.fa-instagram:hover {
  -webkit-transform: rotateY(1turn);
  transform: rotateY(1turn);
}
.fa-envelope:hover {
  -webkit-transform: rotateY(1turn);
  transform: rotateY(1turn);
}
.fa-linkedin-in:hover {
  -webkit-transform: rotateY(1turn);
  transform: rotateY(1turn);
}
.media-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200%;
}
#footer-ending {
  color: white;
}
.list-link {
  marker: none;
}

@media (min-width: 576px) {
  .right-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
} */
/* -----Banner CSS---*/

.banner {
  background-color: #c3f6f4;
  width: 100%;
  height: 190px;
  padding: 48px 0 48px 0;
  justify-content: center;
  position: relative;
  border-radius: 5px;
}
@media (max-width: 550px) {
  .banner {
    height: 130px;
  }
  .text {
    font-size: 40px;
  }
}
.text {
  font-weight: 1000;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  color: rgba(36, 76, 94);
  height: auto;
  width: 100%;
}
.c1 {
  position: absolute;
  z-index: -1;
  bottom: 0px;
  left: 0px;
  width: 20rem;
  height: 20rem;
  color: rgba(100, 21, 255);
  opacity: 0.05;
  transform: translateZ(1px);
}
.c2 {
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  width: 20rem;
  height: 20rem;
  color: rgba(100, 21, 255);
  opacity: 0.05;
  transform: translateZ(1px);
}

.cir {
  position: absolute;
  z-index: 1;
  inset: 0px;
  overflow: hidden;
  border-radius: 0.5rem;
}
/* Banner-2 */
.button-discord {
  display: block;
  height: 50px;
  width: 100px;
  background-color: rgba(36, 76, 94);
  display: flex;
  font-size: large;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: 0;
  border-radius: 0.5rem;
}
.button-discord:hover {
  transition: 0.25s ease-in;
  color: #0fc5b0;
  background-color: rgb(19, 41, 51);
  box-shadow: 0 5px 10px #000000b5;
  text-decoration: 0;
}
@media (max-width: 420px) {
  .button-discord {
    height: 35px;
    font-size: 15px;
  }
  .banner-text {
    width: 98%;
    display: block;
  }
}
#banner-2 {
  max-width: 1335px;
  height: 265px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  margin-top: 40px;
}
.banner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* css for the card section  */

.mainContainerCards {
  background-color: rgb(0, 0, 0);
  padding: 100px 0 150px 0;
  width: 100%;
  overflow: hidden;
}
.heading {
  text-align: center;
  font-weight: bolder;
  font-size: 5.25rem;
  color: #244d5f;
  padding-top: 20px;
  z-index: 2;
}
.feat {
  color: #0fc5b0;
}

/* Card flip effect */
.cardsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.carouselContainer {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

@media (max-width: 801px) {
  .cardsContainer {
    display: none;
  }
}

@media (min-width: 801px) {
  .carouselContainer {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0;
}

.carousel-cards {
  padding: 20px;
  margin: 0 auto;
  display: block;
  height: auto;
  max-width: 232px;
}

.carousel-control-prev {
  margin-right: 30px;
}

.carousel-control-prev-icon {
  padding: 18px;
  margin-right: 90px;
  background-color: #0bc5af;
  border-radius: 50%;
}
.carousel-control-next-icon {
  padding: 18px;
  margin-left: 100px;
  background-color: #0bc5af;
  border-radius: 50%;
}

.cards {
  cursor: pointer;
  display: flex;
  flex: 0 0 18%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 20px;
}

.cards:before {
  display: block;
  content: "";
  background: #7bddd1;
  width: 200px;
  height: 250px;
  position: absolute;
  border-radius: 8%;
  transform: translate(-30px, 40px);
}

.card-img {
  max-height: 45%;
  max-width: 45%;
  object-fit: contain;
}

.card-back-img {
  max-height: 20%;
  max-width: 20%;
  object-fit: contain;
}

.card-title {
  width: 100%;
  text-align: center;
  color: #234d5f;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 30px;
  letter-spacing: 0.1rem;
}

.card-description {
  padding: 20px;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.5);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  border-radius: 8%;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flip-card-front {
  /* background: linear-gradient(to left, #4364f7, #6fb1fc); */
  background: #7bddd1;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-back {
  /* background: linear-gradient(to right, #4364f7, #6fb1fc); */
  background-color: #90d0fe;
  color: #032d60;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #032d60;
}

/* css for the QNA section  */
.Faqcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  justify-content: center;
  margin-top: 60px;
  background-color: #000000;
}
.headingFaq {
  text-align: center;
  padding-top: 1.2em;
}
.faqText {
  text-align: center;
}
.faq_steps {
  position: relative;
  margin-top: auto;
  margin-right: -0.75rem;
  padding: 2.5625rem 2.375rem 1.5625rem;
  color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.faqBox-1 {
  z-index: 5;
  width: 18.4375rem;
  height: 20.4375rem;
  background-color: #11b4af;
  border: 5px solid #000000;
  border-radius: 5px;
  background-image: url(https://lemon.io/wp-content/themes/lemon/assets/images/level-1-number.png);
  background-position: 0.9375rem 96%, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 4.6875rem 6.25rem, contain;
}
.faqBox-2 {
  z-index: 4;
  width: 17.75rem;
  height: 25.3125rem;
  background-color: #11b4af;
  border: 5px solid #000000;
  border-radius: 5px;
  background-image: url(https://lemon.io/wp-content/themes/lemon/assets/images/level-2-number.png);
  background-position: 0.9375rem 97%, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 5.625rem 6.25rem, contain;
}
.faqBox-3 {
  z-index: 3;
  width: 16.5rem;
  height: 30.3125rem;
  background-color: #11b4af;
  border: 5px solid #000000;
  border-radius: 5px;
  background-image: url(https://lemon.io/wp-content/themes/lemon/assets/images/level-3-number.png);
  background-position: 0.9375rem 97%, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 5.625rem 6.25rem, contain;
}
.faqBox-4 {
  z-index: 2;
  width: 15.25rem;
  height: 35.375rem;
  background-color: #11b4af;
  border: 5px solid #000000;
  border-radius: 5px;
  background-image: url(https://lemon.io/wp-content/themes/lemon/assets/images/level-4-number.png);
  background-position: 0.9375rem 97.5%, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 5.9375rem 6.25rem, contain;
}
@media (max-width: 1100px) {
  .Faqcontainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .faqBox-1,
  .faqBox-2,
  .faqBox-3,
  .faqBox-4 {
    height: 15rem;
    width: 29rem;
    margin-top: 5px;
  }
}
@media (max-width: 499px) {
  .faqBox-1,
  .faqBox-2,
  .faqBox-3,
  .faqBox-4 {
    height: fit-content;
    width: 90%;
    margin-top: 5px;
  }
}

/* css for the About section  */

.mainContainer {
  display: flex;
  padding-bottom: 40px;
  background-color: #000000;
  overflow: hidden;
}
.Container {
  padding-top: 75px;
  display: block;
  overflow: hidden;
}
@media (min-width: 801px) {
  .Container {
    padding-left: 50px;
  }
}
.nameContainer {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 300px;
  padding-left: 10px;
}
.headingText {
  padding-left: 10px;
  color: #1ebaa3;
  font-size: 62px;
  height: 100px;
  font-weight: 600;
  padding-top: 3px;
}
.typewriter-text {
  height: 100px;
  width: 175px;
}
.descriptionContainer {
  font-size: x-large;
  font-weight: 500;
  color: #c8c8c8;
  width: 400px;
  padding-left: 10px;
  padding-top: 10px;
  text-align: justify;
}
.descriptionText {
  padding-left: 10px;
}
.featureText {
  list-style: none;
  padding-left: 20px;
  padding-top: 5px;
}
.mainImage {
  height: 600px;
  width: 650px;
  object-fit: contain;
}
.icon {
  height: 35px;
  width: 35px;
}
.featureArea {
  display: flex;
  padding-top: 8px;
  padding-left: 10px;
}
.imageContainer {
  margin-left: auto;
}

/* css for the responsiveness of the about section  */

@media (min-width: 722px) and (max-width: 1051px) {
  .mainContainer {
    display: flex;
    padding-bottom: 50px;
  }
  .headingText {
    padding-left: 10px;
    color: #1ebaa3;
    font-size: 60px;
    padding-top: 5px;
    height: fit-content;
    font-weight: bolder;
  }
  .mainImage {
    height: 450px;
    width: 412px;
  }
  .imageContainer {
    margin-left: auto;
  }
  .descriptionContainer {
    font-size: large;
    font-weight: 500;
    color: #737b8b;
    width: 80%;
  }
}
@media (min-width: 649px) and (max-width: 722px) {
  .mainContainer {
    display: flex;
    padding-bottom: 20px;
  }
  .headingText {
    padding-left: 10px;
    color: #1ebaa3;
    font-size: 60px;
    padding-top: 5px;
    height: fit-content;
    font-weight: bolder;
  }
  .mainImage {
    height: 350px;
    width: 350px;
    padding-top: 35px;
  }
  .imageContainer {
    margin-left: auto;
  }
  .descriptionContainer {
    font-size: large;
    font-weight: 500;
    color: #737b8b;
    width: 80%;
  }
}
@media (max-width: 649px) {
  .mainContainer {
    display: block;
  }
  .headingText {
    padding-left: 10px;
    color: #1ebaa3;
    font-size: 60px;
    padding-top: 5px;
    height: fit-content;
    font-weight: bolder;
  }
  .mainImage {
    height: 350px;
    width: 370px;
  }
  .imageContainer {
    display: flex;
    margin-left: auto;
    align-content: center;
    justify-content: center;
    overflow: hidden;
  }
  .descriptionContainer {
    font-size: large;
    font-weight: 500;
    color: #737b8b;
    width: 95%;
  }
}

/* Launchinsoon */
.Bannner-con {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 70px;
  padding-bottom: 30px;
  background-color: #000000;
  overflow: hidden;
}
/* ResponsivenessFor Banner */
@media (max-width: 649px) {
  .m-scroll,
  .m-scroll-2 {
    height: 100px;
  }
}
/* Banner */
.m-scroll {
  display: flex;
  position: relative;
  justify-content: center;
  width: 102%;
  z-index: 1;
  height: fit-content;
  padding-bottom: 20px;
  margin: auto;
  box-sizing: content-box;
  background-color: #079c74;
  overflow: hidden;
  z-index: 1;
  transform: rotate(-3deg);
}
.m-scroll_title {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 10s ease;
}
.text-scroll {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-content: center;
  overflow: hidden;
}
.LS {
  margin: 0;
  padding-top: 22px;
  font-size: 40px;
  color: rgba(255, 255, 255, 1);
  transition: all 2s ease;
  display: flex;
  animation: scrollText 10s linear infinite;
}
.innnerText {
  text-decoration: none;
  color: white;
  margin-left: 40px;
  overflow: hidden;
  font-family: "Raleway", sans-serif;
}
.innnerText:hover {
  -webkit-text-stroke: 1px white;
  color: transparent;
}
/* BAnner-2 */
.m-scroll-2 {
  display: flex;
  position: relative;
  width: 103%;
  z-index: -1;
  height: fit-content;
  padding-bottom: 20px;
  margin: auto;
  background-color: #030e43;
  overflow: hidden;
  z-index: 1;
  transform: rotate(2deg);
}
.m-scroll_title-2 {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  height: 100%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 10s ease;
}
.text-scroll-2 {
  display: flex;
  animation-delay: 10s;
  height: 100%;
}
.LS {
  margin: 0;
  font-size: 40px;
  color: rgba(255, 255, 255, 1);
  transition: all 2s ease;
  display: flex;
  text-align: center;
  will-change: scroll-position;
  animation: scrollText 8s linear infinite;
}
.innnerText-2 {
  text-decoration: none;
  color: white;
  padding-top: 9px;
  margin-left: 40px;
  font-family: "Raleway", sans-serif;
}
.innnerText-2:hover {
  -webkit-text-stroke: 1px white;
  color: transparent;
}
@keyframes scrollText {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.bannerSection {
  background-color: #000000;
  padding-bottom: 40px;
}
.body {
  background-color: #000000;
}
.Privcy-container {
  background: #000;
  margin-top: 50px;
  padding: 3rem 2rem;
  text-align: justify;
}
.Privcy-container h1 {
  font-size: 3.3rem;
  text-align: center;
  color: #1ebaa3;
  margin-bottom: 3rem;
}
.Privcy-container h2 {
  color: #11b4af;
}
.Privcy-container p {
  color: #fff;
}
.contact-us-Button {
  background-color: #1ebaa3;
  text-decoration: none;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
}
.Terms-Condation {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 3rem;
  text-justify: inter-word;
}
.Terms-Condation h1 {
  color: #1ebaa3;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 600;
}
.Terms-Condation h2 {
  color: #1ebaa3;
  font-weight: 500;
}
.Terms-Condation h4 {
  color: #1ebaa3;
  font-weight: 500;
}
.Terms-Condation p {
  color: #fff;
}
.Cancellation-Refund {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 3rem;
  height: 80vh;
  text-justify: inter-word;
}
.Cancellation-Refund h1 {
  color: #1ebaa3;
  font-weight: 600;
  margin-bottom: 3rem;
  text-align: center;
}
.Cancellation-Refund h3 {
  color: #1ebaa3;
  font-weight: 600;
}
.Cancellation-Refund p {
  color: #fff;
}

/* FOOTER */

@import url("https://fonts.googleapis.com/css2?family=Inconsolata&family=Ubuntu+Mono&family=VT323&display=swap");

/* * {
  margin: 0;
} */

* {
  list-style: none !important;
}

footer a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s linear;
}

.links {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
  width: fit-content;
  transform: translateX(-30px);
}

.footer-link {
  padding: 0.3rem;
}

@media screen and (max-width: 1024px) {
  .links {
    align-items: center;
  }
  .footer-links {
    align-items: center;
    transform: translateX(0);
    margin-top: 0;
    margin-bottom: 1rem;
    width: fit-content;
    padding-left: 0;
  }
}

.footer-link-a:hover {
  color: #00ffd9;
}

.social-icons {
  display: flex;
}

.social-icons-list {
  display: inline-block;
  margin-bottom: 4px;
}

.social-icons-list-a {
  background-color: #393939;
  color: #bbbbbb;
  font-size: 1.3rem;
  display: inline-block;
  line-height: 44px;
  width: 3rem;
  height: 3rem;
  text-align: center;
  margin: 30px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* .links.size-sm a {
  height: 34px;
  width: 34px;
  font-size: 14px;
} */

.links a.discord:hover,
.links a.twitter:hover,
.links a.linkedin:hover,
.links a.instagram:hover {
  background-color: #1ebaa3;
  color: #fff;
}

.footer {
  /* margin-top: 6em; */
  background-color: #202020;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.mail-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer-img {
  text-align: left;
}

.footer-img img {
  width: 17rem;
  padding: 0;
  margin: 20px;
}

.vertical-line {
  width: 1.5px;
  height: 150px;
  background-color: #393939;
}

.footer-img h2 {
  margin-top: -2rem;
  font-family: "VT323";
  margin: 5px;
  color: #1ebaa3;
  font-size: 1rem;
  margin-bottom: 10px;
  position: relative;
  left: 63px;
}

.footer-img a {
  text-decoration: none;
}

.footer-img a:hover {
  color: #1ea792;
}

@media screen and (max-width: 700px) {
  .links a {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    margin: 12px;
  }

  .footer-img {
    margin-bottom: 0;
  }

  .links {
    margin-bottom: 20px;
  }

  .vertical-line {
    display: none;
  }

  .footer-img h2 {
    text-align: center;
    position: static;
    left: 0;
  }
  .social-icons {
    padding-left: 0;
  }
}
